import React, { useState, useEffect } from "react";
import axios from 'axios';
import { withRouter } from "../components/withRouter";
import SidebarOne from "../components/sidebar1";
import { useNavigate } from 'react-router-dom';

function Viewusers(){
  const [cards, setCards] = useState([]);
  const [cards1, setCards1] = useState([]);
  const [cards2, setCards2] = useState([]);
  const navigate = useNavigate();

  const api_url = localStorage.getItem('api_url');
  const user_id = localStorage.getItem('user_id');
  const [formData, setFormData] = useState({
    firstname:"",
    lastname:"",
    userType:"",
    user_role: "",
    address_type: '',
    gender: '',
    landmark: '',
    email: '',
    building: '',
    phone_number: '',
    area: '',
    store_entry_access: '',
    district: '',
    state: '',
    country: '',
    pincode: '',
    user_id:"",
    address_id:'',
    profile: ''
  });

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const res = await axios.get(`${api_url}/getProfile/${user_id}`);
        const data = res.data;
        setCards(data.profile);
        setCards1(data.getaddress);
        setCards2(data.profile_image);

        // Update the formData state with fetched data
        setFormData({
          ...formData,
          firstname: data.profile.firstname,
          lastname: data.profile.lastname,
          userType: data.profile.userType,
          user_role: "Mr",
          address_type: data.getaddress.type,
          gender: data.profile.usergender,
          landmark: data.getaddress.landmark,
          email: data.profile.email,
          building: data.getaddress.building,
          phone_number: data.profile.phone_number,
          area: data.getaddress.area,
          store_entry_access: 'yes',
          district: data.getaddress.district,
          state: data.getaddress.state,
          country: data.getaddress.country,
          pincode: data.getaddress.pincode,
          user_id: user_id,
          address_id: data.getaddress._id,
          profile: data.profile_image.profile_image
        });
      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    };
    fetchCards();
  }, [api_url, user_id]); 

  // Update form data state when input or select box changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Pass form data to updateuserandaddress function
  const updateuserandaddress = () => {
    axios.post(`${api_url}/updateuserdetails_admin`, formData)
      .then((res) => {
        alert("Users Updated successfully");
        navigate("/Customers", { replace: true });
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error updating user details:", error);
      });
  };

  return (
    <div className="view_pro" style={{'display':'flex'}}>
      <div>
        <SidebarOne />
      </div>
      <div className="pr_names">
        <p className="p_name">User Management</p>
        <hr />
        <div className="product_detail">
          <div className="user_name" style={{'display':'flex'}}>
            <div className="user_ims">
              <img className="user_img" src={formData.profile} alt="User profile" />
            </div>
            <div className="user_first">
              <p name="user_role" className="users_mr" value={formData.user_role}>
              {formData.gender === 'male' ? 'Mr' : 'Mrs'}
              </p>

            </div>
            <div className="user_first1">
              <input readOnly name="firstname" type="text" className="users_first" value={formData.firstname} onChange={handleChange} />
            </div>
            <div className="user_last">
              <input readOnly name="lastname" type="text" className="users_last" value={formData.lastname} onChange={handleChange} />
            </div>
            <div className="img_detils1">
              <button className="btn btn-primary" onClick={updateuserandaddress}> Update </button>
            </div>
          </div>
          <br />
          <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '20px' }}>
            <tbody>
              <tr>
                <td className="tab_itmes">
                  Role:
                  <select name="userType" className="item_input" value={formData.userType} onChange={handleChange}>
                    <option value="normal">User</option>
                    <option value="agent">Agent</option>
                    <option value="delivery">Delivery</option>
                  </select>
                </td>
                <td className="tab_itmes">
                  Address Type (Home/Store/Work):
                  <input name="address_type" type="text" className="item_input" value={formData.address_type} onChange={handleChange} />
                </td>
              </tr>
              <tr>
                <td className="tab_itmes">
                  Gender:
                  <select name="gender" className="item_input" value={formData.gender} onChange={handleChange}>
                    <option value="male" selected={formData.gender === 'male'}>Male</option>
                    <option value="female" selected={formData.gender === 'female'}>Female</option>
                  </select>
                </td>
                <td className="tab_itmes">
                  Landmark:
                  <input name="landmark" type="text" className="item_input" value={formData.landmark} onChange={handleChange} />
                </td>
              </tr>
              <tr>
                <td className="tab_itmes">
                  Email Address:
                  <input readOnly name="email" type="text" className="item_input" value={formData.email} onChange={handleChange} />
                </td>
                <td className="tab_itmes">
                  Building:
                  <input name="building" type="text" className="item_input" value={formData.building} onChange={handleChange} />
                </td>
              </tr>
              <tr>
                <td className="tab_itmes">
                  Phone Number:
                  <input readOnly name="phone_number" type="text" className="item_input" value={formData.phone_number} onChange={handleChange} />
                </td>
                <td className="tab_itmes">
                  Area:
                  <input name="area" type="text" className="item_input" value={formData.area} onChange={handleChange} />
                </td>
              </tr>
              <tr>
                <td className="tab_itmes">
                  Store Entry Access:
                  <select disabled name="store_entry_access" className="item_input " value={formData.store_entry_access} onChange={handleChange}>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </td>
                <td className="tab_itmes">
                  District:
                  <input name="district" type="text" className="item_input" value={formData.district} onChange={handleChange} />
                </td>
              </tr>
              <tr>
                <td className="tab_itmes">
                  State:
                  <input name="state" type="text" className="item_input" value={formData.state} onChange={handleChange} />
                </td>
                <td className="tab_itmes">
                  Country:
                  <input name="country" type="text" className="item_input" value={formData.country} onChange={handleChange} />
                </td>
              </tr>
              <tr>
                <td className="tab_itmes">
                  Postal Code:
                  <input name="pincode" type="text" className="item_input" value={formData.pincode} onChange={handleChange} />
                </td>
              </tr>
            </tbody>
          </table>
          <br />
        </div>
      </div>
    </div> 
  );
}

export default withRouter(Viewusers);
