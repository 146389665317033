import React from "react";
import { Link } from "react-router-dom";
import {useNavigate} from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap'
import { Frame } from "../components/Frame";
import { FrameWrapper } from "../components/FrameWrapper";
import { FeatherIconLayout } from "../icons/FeatherIconLayout";
import { IcChevronLeft48Px } from "../icons/IcChevronLeft48Px";
import { IcChevronRight48Px } from "../icons/IcChevronRight48Px";
import { IcFirstPage48Px } from "../icons/IcFirstPage48Px";
import { IcLastPage48Px1 } from "../icons/IcLastPage48Px1";
import DataTable from 'react-data-table-component';
import Header from "../components/Header"
import Footer from "../components/Footer"
import Sidebar from "../components/sidebar"
import SidebarOne from "../components/sidebar1"
import InnerHeader from "../components/InnerHeader"
import {withRouter} from "../components/withRouter"
import axios from 'axios';
import '../assets/css/alertCss.css';

//import '../assets/css/product.css';

class Customers extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      api_url:localStorage.getItem('api_url'),
        data:[],
        prescription:'no',search:'',suc_cls:'',
        errors:  {},
    };     
    this.onChange_prescription = this.onChange_prescription.bind(this); 
    this.onChange_search = this.onChange_search.bind(this); 
    this.handleButtonClick = this.handleButtonClick.bind(this);     
    this.handleImport = this.handleImport.bind(this);
    this.handleDelete = this.handleDelete.bind(this); 
    
  }
  onChange_search(e){
    //console.log("zd",e.target.value);
    this.setState({
        search:e.target.value
    });   
    //this.callgetcustomersapi(e.target.value);  
  }
  onChange_prescription(e){
    //console.log("zd",e.target.value);
    this.setState({
        prescription:e.target.value
    });   
    //this.callgetcustomersapi(e.target.value);  
  }
  handleDelete(e,store_id){
    console.log(store_id);
    const choice = window.confirm(
      "Are you sure you want to delete the Store?"
    )
    if(choice){
      let errors = {};
      axios({
        method: 'get',
        url: this.state.api_url+'/deleteStore/'+store_id,               
        
      }).then(res => {
        const message = res.data.message;       
        errors["message"] = message;
        this.setState({
            errors: errors,
            suc_cls: 'successMsg floatingAlerts1'
            });
            setTimeout(() => {
                this.setState({
                    suc_cls: 'successMsghide'
                });
                this.props.navigate('/customers');
              }, 2000);    
      });
    }
  }
  handleImport(){
    let errors = {};
    axios({
        method: 'post',
        url: this.state.api_url+'/importcustomers',        
             
    }).then(res => {
        const message = res.data.message;
       // console.log(res.data);
        errors["message"] = message;
        this.setState({
            errors: errors,
            suc_cls: 'successMsg floatingAlerts1'
            });
            setTimeout(() => {
                this.setState({
                    suc_cls: 'successMsghide'
                });
                window.location.reload(true);
              }, 5000);    
        
        })
  }
  callgetcustomersapi(){
    //console.log(this.state.prescription); 
    let url;  
    if(this.state.search!= '')  url = this.state.api_url+'/admingetOrders';
    url = this.state.api_url+'/admingetOrders?search='+this.state.search;
    axios({
      method: 'get',
      url: url,             
      
    }).then(res => {
      const data = res.data;
     // console.log(data);
      this.setState({                         
              data: data.transaction_details,             
            });
    });
}
 handleButtonClick (e, id){
  e.preventDefault();
  //return <Link to='/viewProduct'/>;
  localStorage.setItem("store_id",id);
  this.props.navigate('/editStore');
  //this.props.history.push("/login");
 // console.log("Row Id", id);
};
  componentDidMount() {    
    this.callgetcustomersapi(); 
}

    render() {
     // navigate = useNavigate();
  
      const columns = [
        {
            name: 'Order id',
            selector: row => row.name,
            
        },
        {
            name: 'Transaction',
            selector: row => row.transaction,
        },        
        {
            name: 'Total amount',
            selector: row => row.totalAmount,
        },
        {
            name: 'Order status',
            selector: row => row.orderStatus,
        }
        // {
        //   name: 'Action',
        //   selector: row => row.action,
        //   cell: (row) => (
        //     <div>
        //     <button
        //         className="btn btn-outline btn-xs"
        //         onClick={(e) => this.handleButtonClick(e, row.id)}
        //     >
        //         Edit
        //     </button>
        //     <button
        //         className="btn btn-outline btn-xs"
        //         onClick={(e) => this.handleDelete(e, row.id)}
        //     >
        //         Delete
        //     </button>
        //     </div>
            
        // ),
        // },
      
    

    ];    
   
        return (
            <div className="products">
              <div className="div">
                {/* <div className="header">
                  <img className="ic-error-outline" alt="Ic error outline" src={require('../assets/img/ic-error-outline-48px.svg').default} />
                  <div className="group">
                    <div className="overlap-group">
                      <img className="img" alt="Icon bell" src={require('../assets/img/icon-bell.svg').default} />
                      <div className="element-status-indicators">
                        <div className="button-text">3</div>
                      </div>
                    </div>
                  </div>
                  <div className="icon">
                    <div className="overlap-group">
                      <img className="img" alt="Icon bell copy" src={require('../assets/img/icon-bell-copy.svg').default} />
                      <div className="button-text-wrapper">
                        <div className="button-text">7</div>
                      </div>
                    </div>
                  </div>
                  <div className="element-avatars-circle">
                    <div className="overlap">
                      <div className="text-wrapper">JJ</div>
                    </div>
                  </div>
                  <img className="icon-2" alt="Icon" src={require('../assets/img/icon.png').default} />
                  <div className="icons">
                    <div className="text-wrapper-2">Search topic</div>
                    <img className="ic-search" alt="Ic search" src={require('../assets/img/ic-search-48px.svg').default} />
                  </div>
                </div> */}
                { <Header /> }
                {/* <footer className="footer">
                  <div className="support-help-center">
                    Support&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Help
                    Center&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Privacy&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Terms of Service
                  </div>
                </footer> */}
                { <Footer />   }
                 {/* <div className="div-2">
                  <div className="overlap-2">
                    <div className="element-navigations-menus">
                      <div className="element-navigations-menus-2">
                        <div className="text-wrapper-3">Dashboard</div>
                        <FeatherIconLayout className="img-2" />
                      </div>
                    </div>
                    <div className="overlap-3">
                      <div className="element-navigations-menus">
                        <div className="element-navigations-menus-wrapper">
                          <div className="list-item-wrapper">
                            <div className="list-item">Dashboard</div>
                          </div>
                        </div>
                      </div>
                      <div className="element-navigations-menus">
                        <div className="element-navigations-menus-3">
                        <Link to="/customers">
                        <div className="overlap-group-2">
                          <div className="text-wrapper-3">customers</div>
                          <img className="img-2" alt="Ic store" src={require('../assets/img/ic-store-48px-1.svg').default} />
                        </div>
                        </Link>
                        </div>
                      
                        <img
                          className="feather-icon-chevron"
                          alt="Feather icon chevron"
                          src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                        />
                      </div>
                    </div>
                    <div className="overlap-4">
                      <div className="element-navigations-menus">
                        <div className="text-wrapper-3">Customers</div>
                        <img className="img-2" alt="Feather icon users" src={require('../assets/img/feather-icon-users-2.svg').default} />
                      </div>
                      <img
                        className="feather-icon-chevron"
                        alt="Feather icon chevron"
                        src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                      />
                    </div>
                    <div className="element-navigations-menus-4">
                      <div className="element-navigations-menus-3">
                        <div className="text-wrapper-3">Agents</div>
                        <img className="img-2" alt="Feather icon users" src={require('../assets/img/feather-icon-users-1.svg').default} />
                      </div>
                      <img
                        className="feather-icon-chevron"
                        alt="Feather icon chevron"
                        src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                      />
                    </div>
                    <Link className="element-navigations-menus-5" to="/products">
                      <div className="element-navigations-menus-3">
                        
                          <div className="text-wrapper-3">Products</div>
                          <img className="img-2" alt="Feather icon users" src={require('../assets/img/feather-icon-users.svg').default} />
                       
                      </div>
                      <img
                        className="feather-icon-chevron"
                        alt="Feather icon chevron"
                        src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                      />
                    </Link>
                  </div>
                  <div className="img-wrapper">
                    <img className="frame-2" alt="Frame" src={require('../assets/img/frame.svg').default} />
                  </div>
                </div>  */}

{<SidebarOne/>}
               
                <div className="element-navigations-wrapper">
                  <div className="element-navigations">
                    <div className="link">Orders</div>
                  </div>
                </div>
                {/* <div className="overlap-5">
                  <div className="group-2">
                    <div className="table-header">Customers</div>
                    <div className="table-header-2">Dashboard</div>
                    <div className="div-wrapper">
                      <div className="button-text">51</div>
                    </div>
                    <img className="glyph-mail-trash"  src={require('../assets/img/glyph-mail-trash-2.png').default} alt="Glyph mail trash" />
                    <img className="img-3" alt="Ic poll" src={require('../assets/img/ic-poll-48px.svg').default} />
                  </div>
                  <div className="group-3">
                    <div className="table-header-3">Agents</div>
                    <div className="table-header-2">customers</div>
                    <div className="element-status-indicators-2">
                      <div className="button-text">51</div>
                    </div>
                    <img className="glyph-mail-trash" alt="Glyph mail trash" src={require('../assets/img/glyph-mail-trash-1.png').default} />
                    <img className="img-3" alt="Ic store" src={require('../assets/img/ic-store-48px.svg').default} />
                    <div className="overlap-group-wrapper">
                      <div className="overlap-group-3">
                        <div className="table-header-4">Products</div>
                        <div className="element-status-indicators-3">
                          <div className="button-text-2">305</div>
                        </div>
                        <img className="img-3" alt="Glyph mail trash" src={require('../assets/img/glyph-mail-trash.png').default} />
                      </div>
                    </div>
                  </div>
                </div> */}
                {<InnerHeader />}
                <div className="rectangle" />
                <div className="frame-3">
                  {/* <div className="frame-4"> */}
                  
                    {/* <div className="table-header-5">With Prescription</div>
                    <img
                      className="feather-icon-chevron-2"
                      alt="Feather icon chevron"
                      src={require('../assets/img/feather-icon-chevron-right.svg').default}
                    /> */}
                  {/* </div> */}
                {/* <input type="text" class="frame-5" id="prescription" name="prescription"  value={this.state.prescription} onChange={this.onChange_prescription} className="container" /> 
            */}
                  <div className="frame-5" style={{left:"5px"}}>
                  <input type="text" placeholder="Search..." class="searchtxt" value={this.state.search} onChange={this.onChange_search}/>
                   <button type="submit" class="element-icon-button" style={{padding: 0}} onclick={this.callgetcustomersapi()}> <img                      
                      alt="Element icon button"
                      src={require('../assets/img/4-icon-button-1-default-1-default.svg').default}
                    /></button>
                    {/* <div className="table-header-wrapper">
                      <div className="table-header-6">Search by Location</div>
                    </div>
                    <img
                      className="element-icon-button"
                      alt="Element icon button"
                      src={require('../assets/img/4-icon-button-1-default-1-default.svg').default}
                    /> */}
                  </div>
                  {/* <button class="element-button-text-2" onClick={this.handleImport}  style={{left:"985px"}}>
                    <img class="icons-ic-outline" src={require('../assets/img/importic.svg').default} />
                    <div class="button-text" style={{"color":"#7c3a8e"}}>IMPORT</div>
                    </button> */}
                  {/* <div className="element-button-text">
                    <img
                      className="icons-ic-outline"
                      alt="Icons ic outline"
                      src={require('../assets/img/icons-ic-outline-keyboard-arrow-left-24px.svg').default}
                    />
                    <Link to="/addProducts"><div className="button-text-3">CREATE</div></Link> 
                  </div> */}
                </div>
                <div className="overlap-6">
                <div className={this.state.suc_cls}>{this.state.errors.message}</div>
                  <div className="group-4">
                    <div className="tables-default-wrapper">
                      <div className="tables-default">
                        <div className="overlap-group-4">
                          <div className="table-header-7">Product List With Prescription</div>
                        </div>
                      </div>
                    </div>
                    
                    <DataTable
                        columns={columns}
                        data={this.state.data}
                        
                    />
                   
                    
                    
                  </div>
                 
                </div>
              </div>
              {/* <Modal.Dialog>
        <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Modal body text goes here.</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary">Close</Button>
          <Button variant="primary">Save changes</Button>
        </Modal.Footer>
      </Modal.Dialog> */}
            </div>
          );
    }
}

export default withRouter(Customers);
