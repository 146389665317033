import React from "react";
import { Link, NavLink } from 'react-router-dom';
import { Frame } from "./Frame";
import Alert from '@mui/material/Alert';
import DataTable from 'react-data-table-component';
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./sidebar";
import InnerHeader from "./InnerHeader";
import axios from 'axios';
import { withRouter } from "./withRouter";
import SidebarOne from "./sidebar1";
import ProductHierarchy from "./ProductHierarchy";

class viewGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_url: localStorage.getItem('api_url'),
      data: [],
      product: {},
      productattribute: {},
      productspec: [],
      cat_image:{},
      cat_id: localStorage.getItem("cat_id"),
      showAlert: false,
      errors: {},
      suc_cls: '',
      mediaVisible: false,
      specsVisible: false,
      hierarchyVisible: false,
      attributesVisible: false
    };

    this.handleDelete = this.handleDelete.bind(this);
  }
  toggleVisibility = (key) => {
    this.setState((prevState) => ({
      [key]: !prevState[key]
    }));
  };
  handleDelete() {
    const choice = window.confirm(
      "Are you sure you want to delete the product?"
    )
    if (choice) {
      axios({
        method: 'get',
        url: this.state.api_url + '/deleteProduct/' + this.state.product_id,
      }).then(res => {
        const message = res.data.message;
        this.setState({
          errors: { message },
          suc_cls: 'successMsg floatingAlerts1'
        });
        setTimeout(() => {
          this.setState({
            suc_cls: 'successMsghide'
          });
          this.props.history.push('/Products');
        }, 2000);
      });
    }
  }

  componentDidMount() {
    const { api_url, cat_id } = this.state;
    axios({
      method: 'get',
      url: `${api_url}/getCategory/${cat_id}`,
    }).then(res => {
      const data = res.data;
      this.setState({
        product: data.categories
      });

      this.setState({
        cat_image: data.cat_image
      });
    });
  }


  moveeditproduct = () => {
    this.props.navigate("/editGroup");
  };

  render() {
    const { product } = this.state;

    return (
      <div className="view_pro" style={{'display':'flex'}}>
        <div>
        <SidebarOne />
        </div>
        
      <div className="pr_names">
        <div className="pro_names" style={{'display':'flex'}}> 
            <p className="p_name">Categories </p>
            <p className="act_name" style={{'textAlign':'left'}}>Active</p>
        </div>

        <hr></hr>
      <div className="product_detail">
      

            <div className="item_details">
              <div style={{'display':'flex'}}>
                <p className="item_name">Category Name</p>
                  <div className="img_detils_cat">
                    <img className="itme_img" title="edit Group" onClick={this.moveeditproduct} src={require("../assets/images/edit.png")} />
                  </div>
               </div>
                <input readOnly type="text" className="item_input_cat" name="item_id" value={product.cat_name} />
            </div>
            <br></br>
            <div className="item_details">
              <div style={{'display':'flex'}}>
                <p className="item_name">Category Description</p>
               </div>
               <textarea readOnly type="text" className="item_input_cat1" name="item_id" value={product.cat_description} />               
               {/* <p>{cat_description}</p> */}
            </div>

            <div className="item_details">
              <div style={{'display':'flex'}}>
                <p className="item_name">Category Image</p>
               </div>
               <img className="product_imgs" src={this.state.cat_image} />
            </div>

            <div className="item_details">
              <div style={{'display':'flex'}}>
                <p className="item_name">Status</p>
               </div>
               <input type="text" className="item_input_cat" value={product.status}/>
                
            </div>
           
           
      
      </div>
      </div>
      </div>
    );
  }
}

export default withRouter(viewGroup);
