import React, { useState, useEffect } from "react";
import axios from 'axios';
import { withRouter } from "./withRouter";
import SidebarOne from "./sidebar1";
import { useNavigate } from 'react-router-dom';

function CreateProduct(props) {
  const [cards, setCards] = useState([]);
  const [cards1, setCards1] = useState([]);
  const [cards2, setCards2] = useState([]);
  const [item_product, setItemProduct] = useState("");
  const [item_tax_ref, setItemTaxRef] = useState("");
  const [file, setFile] = useState(null);
  const [item_size, setItemSize] = useState("");
  const [item_brand_name, setItemBrandname] = useState("");
  const [item_organic, setItemOrganic] = useState("Yes");
  const [item_ingredients, setItemIngredients] = useState("");
  const [item_category, setItemCategory] = useState("");
  const [item_subcategory, setItemSubcategory] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const api_url = localStorage.getItem('api_url');

  useEffect(() => {
    const fetchCards = async () => {
      try {
        axios.get(api_url + '/getCategories/')
          .then(res => {
            setCards(res.data.categories);
            setCards2(res.data.brand);
          });
      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    };
    fetchCards();
  }, [api_url]);

  const addproductdetails = () => {
    if (!item_brand_name) {
      setErrorMessage("Product Basic Name is required.");
      return;
    }

    const formData = new FormData();
    formData.append("product_name", item_product);
    formData.append("tax_reference", item_tax_ref);
    formData.append("type", item_size);
    formData.append("brand_name", item_brand_name);
    formData.append("organic", item_organic);
    formData.append("ingredients", item_ingredients);
    formData.append("category", item_subcategory);
    formData.append("pic", file);

    axios.post(api_url + "/addproductdetails", formData)
      .then((res) => {
        alert("Product added successfully");
        navigate("/Products", { replace: true });
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error adding product:", error);
      });
  };

  return (
    <div className="view_pro" style={{ display: 'flex' }}>
      <div>
        <SidebarOne />
      </div>
      <div className="pr_names">
        <div className="pro_names" style={{ display: 'flex' }}>
          <p className="p_name">Products </p>
          <p className="act_name" style={{ textAlign: 'left' }}>Active</p>
        </div>
        <hr />
        <div className="product_detail">
          <div style={{ display: 'flex' }}>
            <div className="item_details">
              <div style={{ display: 'flex' }}>
                <img className="itme_img" src={require("../assets/images/type.png")} alt="item" />
                <p className="item_name">Item Title</p>
              </div>
              <input type="text" className="item_input1" name="item_productname" value={item_product} onChange={(e) => setItemProduct(e.target.value)} />
            </div>
            <div className="tax_ref_details">
              <div style={{ display: 'flex' }}>
                <div className="vl"></div>
                <div className="itme_d">
                  <div style={{ display: 'flex' }}>
                    <img className="itme_img" src={require("../assets/images/hash.png")} alt="tax reference" />
                    <p className="item_name">Tax Reference Code</p>
                  </div>
                  <input type="text" className="item_input" name="item_tax_ref" value={item_tax_ref} onChange={(e) => setItemTaxRef(e.target.value)} />
                </div>
              </div>
            </div>
            <button className="itme_img1" onClick={addproductdetails}>Save</button>
          </div>
          <hr />
          <div style={{ display: 'flex' }}>
            <div className="tax_ref_details1">
              <div style={{ display: 'flex' }}>
                <div className="vl"></div>
                <div className="itme_d">
                  <div style={{ display: 'flex' }}>
                    <img className="itme_img" src={require("../assets/images/hash.png")} alt="brand name" />
                    <p className="item_name">Brand Name</p>
                  </div>
                  <select className="item_input1" name="item_tax_ref" value={item_brand_name} onChange={(e) => setItemBrandname(e.target.value)} >
                    <option value="">Select a Brand Name</option>
                    {cards2.map((card2) => (
                      <option key={card2._id} value={card2._id}>
                        {card2.brand_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="media">
          <div className="pro_de_ref">
            <p><b>Media</b></p>
            <hr />
            <br />
            <p className="img_and_vid"><b>Images & Videos</b></p>
            <input type="file" onChange={handleFileChange} />
          </div>
        </div>
        <br />
        <div className="item_hi pro_de_ref">
          <hr />
          <p>Category</p>
          <select
            name="category_name"
            value={item_category}
            onChange={(e) => {
              setItemCategory(e.target.value);
              setItemSubcategory("");
              if (e.target.value) {
                axios.get(api_url + "/getsubcat/" + e.target.value)
                  .then((res) => {
                    setCards1(res.data.subcat_details);
                  })
                  .catch((error) => {
                    console.error("Error fetching subcategories:", error);
                  });
              } else {
                setCards1([]);
              }
            }}
            className="item_input3"
          >
            <option value="">Select a Category</option>
            {cards.map((card) => (
              <option key={card.cat_id} value={card.cat_id}>
                {card.cat_name}
              </option>
            ))}
          </select>
          <p>Sub category</p>
          <select
            name="subcategory_name"
            value={item_subcategory}
            onChange={(e) => setItemSubcategory(e.target.value)}
            className="item_input3"
          >
            <option value="">Select a Sub Category</option>
            {cards1.map((card1) => (
              <option key={card1._id} value={card1._id}>
                {card1.sub_cat_name}
              </option>
            ))}
          </select>
        </div>
        <br />
        <div className="attributes pro_de_ref">
          <p><b>Details</b></p>
          <hr />
          <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '-17px' }}>
            <thead>
              <tr>
                <th className="tab_itmes">
                  <p>Type :</p>
                  <select className="item_input" name="item_size" value={item_size} onChange={(e) => setItemSize(e.target.value)} >
                    <option value="">Select Type</option>
                    <option value="Veg">Veg</option>
                    <option value="Non Veg">Non Veg</option>
                  </select>
                </th>
                <th className="tab_itmes">
                  <p> Ingredients :</p>
                  <input type="text" className="item_input2" name="item_ingredients" value={item_ingredients} onChange={(e) => setItemIngredients(e.target.value)} />
                </th>
              </tr>
              <tr>
                <th className="tab_itmes">
                  <p>Organic Certified</p>
                  <select type="text" className="item_input" name="item_organic" value={item_organic} onChange={(e) => setItemOrganic(e.target.value)} >
                    <option>Yes</option>
                    <option>No</option>
                  </select>
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <br />
      </div>
    </div>
  );
}

export default withRouter(CreateProduct);
