import React, { useState, useEffect } from "react";
import axios from 'axios';
import { withRouter } from "./withRouter";
import SidebarOne from "./sidebar1";
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';

function CreateCoupon(props) {
  const [coupon_code, setCouponCode] = useState('');
  const [amount_upto, setAmountUpto] = useState('');
  const [offer, setOffer] = useState('');
  const [valid_date, setValidDate] = useState('');
  const [data1, setData1] = useState([]);
  const navigate = useNavigate();
  const [validationMessages, setValidationMessages] = useState({
    coupon_code: "",
    amount_upto: "",
    offer: "",
    valid_date: ""
  });

  const api_url = localStorage.getItem('api_url');

  const addproductdetails = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    if (validateForm()) {
      const formData = {
        coupon_code,
        amount_upto,
        offer,
        valid_date
      };

      try {
        const res = await axios.post(`${api_url}/addCoupons`, formData);

        if (res.data.status === "true") {
          alert("Coupon added successfully");
          navigate("/Coupons", { replace: true });
        } else {
          alert(res.data.message); // Show the server's error message
          window.location.reload();
        }

      } catch (error) {
        console.error("Error adding coupon:", error);
        alert("Error adding coupon: " + error.message);
      }
    }
  };

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const res = await axios.get(`${api_url}/getsubs`);
        const data = res.data.products;
        setData1(data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchCards();
  }, [api_url]);

  const validateForm = () => {
    let isValid = true;
    const errors = {
      coupon_code: "",
      amount_upto: "",
      offer: "",
      valid_date: ""
    };

    if (!coupon_code.trim()) {
      errors.coupon_code = "Coupon code is required.";
      isValid = false;
    }

    if (!amount_upto.trim()) {
      errors.amount_upto = "Amount is required.";
      isValid = false;
    }

    if (!offer.trim()) {
      errors.offer = "Offer percentage is required.";
      isValid = false;
    } else if (isNaN(offer) || parseFloat(offer) <= 0 || parseFloat(offer) > 100) {
      errors.offer = "Offer percentage must be a number between 1 and 100.";
      isValid = false;
    }

    if (!valid_date) {
      errors.valid_date = "Valid date is required.";
      isValid = false;
    }

    setValidationMessages(errors);
    return isValid;
  };

  return (
    <div className="view_pro" style={{ display: 'flex' }}>
      <div>
        <SidebarOne />
      </div>

      <div className="pr_names">
        <div className="pro_names" style={{ display: 'flex' }}>
          <p className="p_name">Coupons </p>
          <p className="act_name" style={{ textAlign: 'left' }}>Active</p>
        </div>

        <hr />

        <div className="product_detail">
          <form onSubmit={addproductdetails}>
            <div className="item_details">
              <div style={{ display: 'flex' }}>
                <p className="item_name">Coupon Code</p>
                <button type="submit" className="itme_img11">Save</button>
              </div>
              <input
                type="text"
                className="item_input_cat"
                name="coupon_code"
                value={coupon_code}
                onChange={(e) => setCouponCode(e.target.value)}
                
              />
              {validationMessages.coupon_code && <Alert severity="error">{validationMessages.coupon_code}</Alert>}
            </div>
            <br />

            <div className="item_details">
              <div style={{ display: 'flex' }}>
                <p className="item_name">Get amount above offer</p>
              </div>
              <input
                type="text"
                className="item_input_cat"
                name="amount_upto"
                value={amount_upto}
                onChange={(e) => setAmountUpto(e.target.value)}
                
              />
              {validationMessages.amount_upto && <Alert severity="error">{validationMessages.amount_upto}</Alert>}
            </div>

            <br />

            <div className="item_details">
              <div style={{ display: 'flex' }}>
                <p className="item_name">Offer Percentage</p>
              </div>
              <input
                type="number"
                className="item_input_cat"
                name="offer"
                value={offer}
                onChange={(e) => setOffer(e.target.value)}
                
              />
              {validationMessages.offer && <Alert severity="error">{validationMessages.offer}</Alert>}
            </div>

            <div className="item_details">
              <div style={{ display: 'flex' }}>
                <p className="item_name">Valid Date</p>
              </div>
              <input
                type="date"
                className="item_input_cat"
                name="valid_date"
                value={valid_date}
                onChange={(e) => setValidDate(e.target.value)}
                
              />
              {validationMessages.valid_date && <Alert severity="error">{validationMessages.valid_date}</Alert>}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default withRouter(CreateCoupon);
