import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';


const Orderstore = () => {
  const [cards, setCards] = useState([]);
  const [cards1, setCards1] = useState([]);
  const [cards2, setCards2] = useState([]);
  const [cards3, setCards3] = useState([]);
  const [cards4, setCards4] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");
  const [selectedAgent, setSelectedAgent] = useState("");
  const navigate = useNavigate();
  const handleAddToCart = async (cart_id, id, price1, qty) => {
    try {
      const product_id = id; 
      const user_id = localStorage.getItem('admin_id'); 
      const quantity = qty;
      const price = price1;
  
      const api_url = localStorage.getItem('api_url');
      const response = await axios.post(api_url + '/addtocart', {
        product_id,
        user_id,
        quantity,
        price,
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        transformRequest: [(data, headers) => {
          const params = new URLSearchParams();
          for (const key in data) {
            params.append(key, data[key]);
          }
          return params.toString();
        }],
      });
      const check_res = response.data.status;
  
    //   if (check_res) {
    //     handlesaveCartremove1(cart_id)
    //     window.location.reload();
    //   }
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };


  useEffect(() => {
    const fetchCards = async () => {
      try {
        const cart_id = localStorage.getItem('cart_id');
        const api_url = localStorage.getItem('api_url');
        const response = await axios.get(api_url + '/getorder_store_Details/'+cart_id);
        setCards(response.data.cartItems);
        setCards1(response.data.user_details);
        setCards2(response.data.user_address);
        setCards3(response.data.store);
        setCards4(response.data.agents);
      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    };

    fetchCards();
  }, []);

  const handleAssign = async () => {
    try {
      const api_url = localStorage.getItem('api_url');
      const response = await axios.post(api_url + '/assigncarts_agents', {
        cart_id: localStorage.getItem('cart_id'),
        store_id: selectedStore,
        agent_id: selectedAgent,
      },{
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        transformRequest: [(data, headers) => {
          const params = new URLSearchParams();
          for (const key in data) {
            params.append(key, data[key]);
          }
          return params.toString();
        }],
      });
      const check_res1 = response.data.status;

        if(check_res1){
            alert("Assign stores and agents");
            navigate("/Orderassign", { replace: true });
        }
      
      // Handle response as needed
    } catch (error) {
      console.error("Error assigning store and agent:", error);
    }
  };

  return (
    <div>
      {cards.map((product) => (
        <div>
           <div className="order-card" key={product.id}>
               <div className="product-info">
                    <div className="product-details">
                        <div className="dis_play_pro">
                        <p className="product-name"> Product Name : <b>{product.product_name}</b></p>
                        <p className="product-name1"> Brand Name : <b>{product.brand_name}</b></p>
                        </div>
                        <div className="dis_play_pro">
                        <p className="product-name"> Price : <b>₹ {product.price}</b></p>
                        <p className="product-name1"> Quantity : <b> {product.quantity}</b></p>
                        </div>

                        <div className="dis_play_pro">
                        <p className="product-name"> Order Id : <b> {product.order_id}</b></p>
                        <p className="product-name1"> Pyment Success Id : <b> {product.pay_success}</b></p>
                        </div>
                    </div>
               </div>
           </div>
        </div>    
      ))}
   <hr />

      <div className="user_name_details">
                <div className="dis_play_pro">
                        <p className="product-name">UserName : <b>{cards1.firstname} {cards1.lastname}</b></p>
                        <p className="product-name">Email : <b>{cards1.email}</b></p>
                        <p className="product-name">Mobile : <b>{cards1.phone_number}</b></p>
                </div>
      </div>

   <hr />

   <div className="user_name_details">
                <div className="dis_play_pro1">
                        <p className="product-name"> Type : <b>{cards2.type}</b></p>
                        <p className="product-name">Address : <b>{cards2.landmark} , {cards2.building} ,{cards2.area} ,{cards2.district} ,{cards2.state} , {cards2.country}</b></p>
                        <p className="product-name">Pincode : <b>{cards2.pincode}</b></p>
                </div>
        <hr />   
      </div>

      <div className="user_name_details">
                <div className="dis_play_pro1">
                <p className="item_name">Store : </p>
                         <select className="item_input" name="store_name" value={selectedStore} onChange={(e) => setSelectedStore(e.target.value)}>
                             <option value="">Select Store</option>
                             {cards3.map((product1) => (
                                <option key={product1._id} value={product1._id}>{product1.store_name} - {product1.pincode}</option>
                             ))}
                         </select>

                         <br></br>
                         <p className="item_name">Agent : </p>
                         <select className="item_input" name="store_name" value={selectedAgent} onChange={(e) => setSelectedAgent(e.target.value)}>
                             <option value="">Select Agent</option>
                             {cards4.map((product2) => (
                                <option key={product2._id} value={product2._id}>{product2.agent_name} - {product2.pincode}</option>
                             ))}
                         </select>
                         <br></br>
                         <br></br>
                </div>
      </div>

      <button className="btn btn-primary" onClick={handleAssign}>Assign stores and agent</button>
      <br></br>
 </div>
    
  );
};

export default Orderstore;
