import React, { useState, useEffect } from "react";
import axios from 'axios';
import { withRouter } from "./withRouter";
import SidebarOne from "./sidebar1";
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';

function EditOffer(props) {
  const [offer_name, setOfferName] = useState('');
  const [product_ids, setProductIds] = useState([]);
  const [offer, setOffer] = useState('');
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [validationMessages, setValidationMessages] = useState({
    offer_name: "",
    product_ids: "",
    offer: ""
  });
  const navigate = useNavigate();
  const api_url = localStorage.getItem('api_url');
  const id = localStorage.getItem('id');
  const offer_id = id;

  const updateOfferDetails = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      const formData = {
        offer_id,
        offer_name,
        product_id: product_ids.join(','),
        offer
      };

      try {
        await axios.post(`${api_url}/updateofferdetails`, formData);
        alert("Offer updated successfully");
        navigate("/Offers", { replace: true });
      } catch (error) {
        console.error("Error updating offer:", error);
      }
    }
  };

  useEffect(() => {
    const fetchOfferDetails = async () => {
      try {
        const res = await axios.get(`${api_url}/getoffer1/${id}`);
        const data = res.data;
        setOfferName(data.offer.offer_name);
        setProductIds(data.offer.product_id.split(','));
        setOffer(data.offer.offer);
        setProducts(data.products);
      } catch (error) {
        console.error("Error fetching offer data:", error);
      }
    };
    fetchOfferDetails();
  }, [api_url, id]);

  const handleProductChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setProductIds([...product_ids, value]);
    } else {
      setProductIds(product_ids.filter((id) => id !== value));
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {
      offer_name: "",
      product_ids: "",
      offer: ""
    };

    if (!offer_name.trim()) {
      errors.offer_name = "Offer name is required.";
      isValid = false;
    }

    if (product_ids.length === 0) {
      errors.product_ids = "Select at least one product.";
      isValid = false;
    }

    if (!offer.trim()) {
      errors.offer = "Offer percentage is required.";
      isValid = false;
    } else if (isNaN(offer) || parseFloat(offer) <= 0 || parseFloat(offer) > 100) {
      errors.offer = "Offer percentage must be a number between 1 and 100.";
      isValid = false;
    }

    setValidationMessages(errors);
    return isValid;
  };

  const filteredProducts = products
    .filter(product =>
      product.product_name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => {
      // Sort by whether the product name starts with the search query
      const aStartsWithQuery = a.product_name.toLowerCase().startsWith(searchQuery.toLowerCase());
      const bStartsWithQuery = b.product_name.toLowerCase().startsWith(searchQuery.toLowerCase());

      if (aStartsWithQuery && !bStartsWithQuery) return -1;
      if (!aStartsWithQuery && bStartsWithQuery) return 1;
      return 0;
    });

  return (
    <div className="view_pro" style={{ display: 'flex' }}>
      <div>
        <SidebarOne />
      </div>

      <div className="pr_names">
        <div className="pro_names" style={{ display: 'flex' }}>
          <p className="p_name">Offers </p>
          <p className="act_name" style={{ textAlign: 'left' }}>Active</p>
        </div>

        <hr />

        <div className="product_detail">
          <form onSubmit={updateOfferDetails}>
            <div className="item_details">
              <div style={{ display: 'flex' }}>
                <p className="item_name">Offer Name</p>
                <button className="itme_img11" type="submit">Update</button>
              </div>
              <input
                type="text"
                className="item_input_cat"
                name="offer_name"
                value={offer_name}
                onChange={(e) => setOfferName(e.target.value)}
                
              />
              {validationMessages.offer_name && <Alert severity="error">{validationMessages.offer_name}</Alert>}
            </div>
            <br />

            <div className="item_details">
              <div style={{ display: 'flex' }}>
                <p className="item_name">Product Names</p>
              </div>
              <br />
              <input
                type="text"
                className="item_input_cat"
                placeholder="Search Product Name"
                value={searchQuery}
                onChange={handleSearch}
              />
              {validationMessages.product_ids && <Alert severity="error">{validationMessages.product_ids}</Alert>}
              {filteredProducts.map((product) => (
                <div key={product._id}>
                  <label>
                    <input
                      type="checkbox"
                      value={product._id}
                      checked={product_ids.includes(product._id)}
                      onChange={handleProductChange}
                    />
                    {product.product_name}
                  </label>
                </div>
              ))}
            </div>
            <br />

            <div className="item_details">
              <div style={{ display: 'flex' }}>
                <p className="item_name">Offer Percentage</p>
              </div>
              <input
                type="number"
                className="item_input_cat"
                name="offer"
                value={offer}
                onChange={(e) => setOffer(e.target.value)}
                
              />
              {validationMessages.offer && <Alert severity="error">{validationMessages.offer}</Alert>}
            </div>
            <br />
          </form>
        </div>
      </div>
    </div>
  );
}

export default withRouter(EditOffer);
