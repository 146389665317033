import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';

const DatatableOffers = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [pic, setPic] = useState([]);
  const [filename, setFilename] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const api_url = localStorage.getItem('api_url');
      const res = await axios.get(api_url + '/getCoupons');
      const data = await res.data.offers;
      setData(data);
      setFilteredData(data);
    };

    fetchData();
  }, []);

  const handleFilterChange = (e) => {
    const value = e.target.value || "";
    setFilterText(value);
    filterData(value, sortField, sortDirection);
  };
  const handleSelectAll = (isChecked) => {
    setAllSelected(isChecked);
    if (isChecked) {
      setSelectedRows(filteredData);
    } else {
      setSelectedRows([]);
    }
  };

  const filterData = (filterValue, sortField, sortDirection) => {
    const filteredItems = data.filter((item) =>
      Object.keys(item).some(
        (key) =>
          key !== "id" &&
          item[key].toString().toLowerCase().includes(filterValue.toLowerCase())
      )
    );

    if (sortField && sortDirection) {
      filteredItems.sort((a, b) => {
        const fieldA = a[sortField].toString().toLowerCase();
        const fieldB = b[sortField].toString().toLowerCase();
        return sortDirection === "asc" ? fieldA.localeCompare(fieldB) : fieldB.localeCompare(fieldA);
      });
    }

    setFilteredData(filteredItems);
  };

  const handleSort = (column, sortDirection) => {
    setSortField(column.selector);
    setSortDirection(sortDirection);
    filterData(filterText, column.selector, sortDirection);
  };

  const handleRowSelected = (isChecked, row) => {
    setSelectedRows((prevSelectedRows) => {
      if (isChecked) {
        return [...prevSelectedRows, row];
      } else {
        return prevSelectedRows.filter((selectedRow) => selectedRow.id !== row.id);
      }
    });
  };

  const web_url = localStorage.getItem('web_url');
  const navigate = useNavigate();

  const handleDivClick = (id) => {
    localStorage.setItem("id", id);
    navigate("/viewCoupon", { replace: true });
  };

  const handleDivClick1 = (id) => {
    localStorage.setItem("id", id);
    navigate("/editCoupon", { replace: true });
  };

  const handleDivClick2 = () => {
    navigate("/createCoupon", { replace: true });
  };

  const exportSelectedRows = () => {
    const selectedRowsData = selectedRows.map(row => {
      return {
        'Coupon ID': row.id,
        'Coupon Code': row.coupon_code,
        'Get Amount above offer': row.amount_upto,
        'Offer percentage': row.offer,
        'Valid Date': row.valid_date
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(selectedRowsData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "SelectedRows");
    XLSX.writeFile(workbook, "Coupons.xlsx");
  };

  const exportSelectedRows1 = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleFileUpload = (e) => {
    document.getElementById("fileID").click();
  };

  const fileUploadclick = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "text/csv") {
      setPic(file);
      setFilename(file.name);
    } else {
      alert("Please upload a valid CSV file.");
    }
  };

  const handleImport = () => {
    let errors = {};
    var agentimp = new FormData();
    agentimp.append('pic', pic);
    const api_url = localStorage.getItem('api_url');
    axios({
      method: 'post',
      url: api_url + '/importCoupons',
      data: agentimp
    }).then(res => {
      alert("Coupons details imported successfully");
      navigate("/Coupons", { replace: true });
      window.location.reload();
    });
  };

  const columns = [
    {
      name: <input
      type="checkbox"
      onChange={(e) => handleSelectAll(e.target.checked)}
      checked={allSelected}
    />,
      cell: (row) => (
        <input
          type="checkbox"
          onChange={(e) => handleRowSelected(e.target.checked, row)}
          checked={selectedRows.map((selectedRow) => selectedRow.id).includes(row.id)}
        />
      ),
    },
    {
      name: 'Coupon ID',
      selector: row => row.id,
      sortable: true,
    },
    {
      name: 'Coupon Code',
      selector: row => row.coupon_code,
      sortable: true,
    },
    {
      name: 'Get Amount Above Offer',
      selector: row => row.amount_upto,
      sortable: true,
    },
    {
      name: 'Offer Percentage',
      selector: row => row.offer,
      sortable: true,
    },
    {
      name: 'Valid Date',
      selector: row => row.valid_date,
      sortable: true,
    },
    {
      name: 'Action',
      selector: row => row.action,
      cell: (row) => (
        <div>
          <button
            className="tableBtn"
            onClick={() => handleDivClick(row.id)}
          >
            View
          </button>
          <button
            className="tableBtn"
            onClick={() => handleDivClick1(row.id)}
          >
            Edit
          </button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="pro_buttons">
        <button className="import_tem" onClick={exportSelectedRows1}>IMPORT</button>
        <button className="export_tem" onClick={exportSelectedRows}>EXPORT</button>
        <button className="create_tem" onClick={handleDivClick2}>CREATE</button>
      </div>
      <p className="produc_de">Coupons</p>
      <div className="data_margin">
        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15,50,100,500,1000,10000]}
          highlightOnHover
          onSort={handleSort}
          sortServer
          customStyles={{
            headCells: {
              style: {
                fontWeight: "bold",
              },
            },
            rows: {
              style: {
                "&:nth-of-type(odd)": {
                  backgroundColor: "#f3f3f3",
                },
              },
            },
          }}
        />
      </div>
      <Modal open={openModal} onClose={handleModalClose} id="fileupload">
        <div className="container">
          <h3>Import</h3>
          <hr />
          <div className="drop_box">
          { (pic == '') ?
                  <div>
                  <header>
                      <h4 className="choose_import">Choose the file to be imported</h4>
                    </header>
                    <p className="choose_import1">Only csv formats are supported</p>
                    <span className="choose_import2">Maximum upload file size is 5MB</span>
                    <br></br>
                  <input type="file" hidden accept=".csv" id="fileID" style={{display:"none"}} onChange={fileUploadclick}/>
                  <button class="btn choose_import3"  onClick={handleFileUpload}>Upload File</button> 
                   <br></br>
                    <a className="down_sample" href={web_url + "gallery/templates/coupons.csv"}>Download sample template for import</a>
                  </div> : '' }
                  { (pic == '') ?
                  <form action="" id="uploadForm" method="post" style={{display:"none"}}> 
                  <div class="form">
                  <h4>{filename}</h4>    
                  <button class="btn">Upload</button>
                  </div>
                  </form> : <form action="" id="uploadForm" method="post"> 
                  <div class="form">
                  <h4>{filename}</h4>    
                  <button class="btn" type="button" onClick={handleImport}>Upload</button>
                  </div>
                  </form> }
            
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DatatableOffers;
