import React from "react";
import axios from 'axios';
import { withRouter } from "./withRouter";
import SidebarOne from "./sidebar1";
import DatatableProductscat from "./datatableProductscat";

class viewSubgroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_url: localStorage.getItem('api_url'),
      data: [],
      product: {},
      productattribute: {},
      productspec: [],
      cat_image:{},
      cat_name:{},
      sub_cat_id: localStorage.getItem("sub_cat_id"),
      showAlert: false,
      errors: {},
      suc_cls: '',
      mediaVisible: false,
      specsVisible: false,
      hierarchyVisible: false,
      attributesVisible: false
    };

    this.handleDelete = this.handleDelete.bind(this);
  }
  toggleVisibility = (key) => {
    this.setState((prevState) => ({
      [key]: !prevState[key]
    }));
  };
  handleDelete() {
    const choice = window.confirm(
      "Are you sure you want to delete the product?"
    )
    if (choice) {
      axios({
        method: 'get',
        url: this.state.api_url + '/deleteProduct/' + this.state.product_id,
      }).then(res => {
        const message = res.data.message;
        this.setState({
          errors: { message },
          suc_cls: 'successMsg floatingAlerts1'
        });
        setTimeout(() => {
          this.setState({
            suc_cls: 'successMsghide'
          });
          this.props.history.push('/Products');
        }, 2000);
      });
    }
  }

  componentDidMount() {
    const { api_url, sub_cat_id } = this.state;
    axios({
      method: 'get',
      url: `${api_url}/getsubcategory/${sub_cat_id}`,
    }).then(res => {
      const data = res.data;
      this.setState({
        product: data.subcategories
      });

      this.setState({
        sub_cat_image: data.sub_cat_image
      });

      this.setState({
        cat_name: data.category_name
      });
    });
  }


  moveeditproduct = () => {
    this.props.navigate("/editSubgroup");
  };

  render() {
    const { product } = this.state;

    return (
      <div className="view_pro" style={{'display':'flex'}}>
        <div>
        <SidebarOne />
        </div>
        
      <div className="pr_names">
        <div className="pro_names" style={{'display':'flex'}}> 
            <p className="p_name">Sub Categories </p>
            <p className="act_name" style={{'textAlign':'left'}}>Active</p>
        </div>

        <hr></hr>
      <div className="product_detail">
      

            <div className="item_details">
              <div style={{'display':'flex'}}>
                <p className="item_name">Sub Category Name</p>
                  <div className="img_detils_cat">
                    <img className="itme_img" title="edit Group" onClick={this.moveeditproduct} src={require("../assets/images/edit.png")} />
                  </div>
               </div>
                <input readOnly type="text" className="item_input_cat" name="item_id" value={product.sub_cat_name} />
            </div>
            <br></br>
            <div className="item_details">
              <div style={{'display':'flex'}}>
                <p className="item_name">Category Name</p>
               </div>
               <input readOnly type="text" className="item_input_cat" value={this.state.cat_name}/>
            </div>

            <div className="item_details">
              <div style={{'display':'flex'}}>
                <p className="item_name">Sub Category Image</p>
               </div>
               <img className="product_imgs" src={this.state.sub_cat_image} />
            </div>

            <div className="item_details">
              <div style={{'display':'flex'}}>
                <p className="item_name">Status</p>
               </div>
               <input readOnly type="text" className="item_input_cat" value={product.status}/>
                
            </div>
           <br></br>
           <div>
           <DatatableProductscat/>
           </div>
      
      </div>
      </div>
      </div>
    );
  }
}

export default withRouter(viewSubgroup);
